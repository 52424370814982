<template>
  <div class="d-flex flex-fill fill-height justify-center align-center">
    <v-container>
      <v-row>
        <v-col
          cols="12"
          sm="8"
          offset-sm="2"
          md="6"
          offset-md="3"
          lg="4"
          offset-lg="4"
        >
          <v-card>
            <v-card-title class="justify-center brown white--text">
              Fechar Caixa
            </v-card-title>
            <v-card-text>
              <div class="d-flex justify-space-between py-4">
                <div>
                  <strong>Data: </strong>
                  {{ new Date() | moment("DD/MM/YYYY") }}
                </div>
                <div>
                  <strong>Hora: </strong> {{ new Date() | moment("hh:mm:ss") }}
                </div>
              </div>
              <div>
                <v-text-field-money
                  v-bind:properties="properties"
                  v-bind:options="options"
                  v-model.number="value_money"
                  label="Valor em Dinheiro:"
                ></v-text-field-money>
                <v-text-field-money
                  v-bind:properties="properties"
                  v-bind:options="options"
                  v-model.number="value_card"
                  label="Valor em Cartão:"
                ></v-text-field-money>
                <v-text-field-money
                  v-bind:properties="properties"
                  v-bind:options="options"
                  v-model.number="value_check"
                  label="Valor em Cheque:"
                ></v-text-field-money>
                <v-text-field-money
                  v-bind:properties="{ ...properties, disabled: true }"
                  v-bind:options="options"
                  label="Total:"
                  :value="total"
                ></v-text-field-money>
              </div>
            </v-card-text>
            <v-card-actions class="justify-space-between">
              <v-btn @click="$router.push('/')" text color="error">
                Fechar
              </v-btn>
              <v-btn color="brown" @click="send"> Salvar </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import snackbar from "../_mixins/snackbar";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "CloseBox",
  mixins: [snackbar],
  data() {
    return {
      value_money: 0,
      value_check: 0,
      value_card: 0,
    };
  },
  methods: {
    ...mapMutations(["removeBox"]),
    async send() {
      if (!this.box) {
        this.snackWarning("Não existe caixa aberto!");
        return;
      }
      try {
        const { value_money, value_card, value_check } = this;
        const result = await this.$axios.post("/box/close", {
          boxId: this.box.id,
          value_money,
          value_card,
          value_check,
        });
        const {
          data: { close },
        } = result;
        if (close && close.id) {
          this.snackSuccess("Caixa Fechado com Sucesso!");
          this.removeBox();
          sessionStorage.setItem("transfer", JSON.stringify(close));
          await this.$router.push({ name: "report_closebox" });
        }
      } catch (e) {
        const {
          response: {
            data: { error },
          },
        } = e;
        error && this.snackError(error);
      }
    },
  },
  computed: {
    ...mapGetters(["box"]),
    total() {
      return this.value_money + this.value_check + this.value_card || 0;
    },
    properties: () => ({ filled: true, prefix: "R$", placeholder: " " }),
    options: () => ({
      locale: "pt-BR",
      length: 11,
      precision: 2,
      empty: null,
    }),
  },
};
</script>

<style scoped></style>
